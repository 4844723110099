<template>
  <div class="census">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/shop' }">门店管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/shop' }">商家列表</el-breadcrumb-item>
      <el-breadcrumb-item>统计分析</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="screen">
      <div class="screen-btns">
        <el-button
          @click="changeActive(1)"
          :style="{
            background: active == 1 ? '#cd0550' : '',
            border: active == 1 ? 'noen' : '1px solid #eee',
            color: active == 1 ? '#fff' : '',
          }"
          size="small"
          plain
          >最近一个月</el-button
        >
        <el-button
          @click="changeActive(2)"
          :style="{
            background: active == 2 ? '#cd0550' : '',
            border: active == 2 ? 'none' : '1px solid #eee',
            color: active == 2 ? '#fff' : '',
          }"
          size="small"
          plain
          >最近三个月</el-button
        >
        <el-button
          @click="changeActive(3)"
          :style="{
            background: active == 3 ? '#cd0550' : '',
            border: active == 3 ? 'none' : '1px solid #eee',
            color: active == 3 ? '#fff' : '',
          }"
          size="small"
          plain
          >最近六个月</el-button
        >
        <el-button
          @click="changeActive(4)"
          :style="{
            background: active == 4 ? '#cd0550' : '',
            border: active == 4 ? 'none' : '1px solid #eee',
            color: active == 4 ? '#fff' : '',
          }"
          size="small"
          plain
          >最近一年</el-button
        >
        <el-button
          @click="changeActive(5)"
          :style="{
            background: active == 5 ? '#cd0550' : '',
            border: active == 5 ? 'none' : '1px solid #eee',
            color: active == 5 ? '#fff' : '',
          }"
          size="small"
          plain
          >最近两年</el-button
        >
      </div>
    </div>
    <div class="shop-census-info">
      <div class="card">
        <div class="card-title">当前门店</div>
        <div class="shop-census-data-one">
          <img src="../../assets/imgs/location.png" />
          <p>{{ shopName }}</p>
        </div>
      </div>
      <div class="two-card">
        <div class="card">
        <div class="card-title">数据总览</div>
        <div class="shop-census-data">
          <div>
            <div class="data-title">用户收藏次数</div>
            <p class="data-content">{{ shopInfo.good_collect_num }}</p>
          </div>
          <div>
            <div class="data-title">店铺访问次数</div>
            <p class="data-content">{{ shopInfo.page_views }}</p>
          </div>
          <div>
            <div class="data-title">用户评分</div>
            <p class="data-content" style="color: #ffb85f">
              {{ shopInfo.score }}
            </p>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-title">订单统计</div>
        <div class="shop-census-data">
          <div>
            <div class="data-title">已完成订单数量</div>
            <p class="data-content">{{ shopInfo.order_num }}</p>
          </div>
          <div>
            <div class="data-title">取消订单数量</div>
            <p class="data-content">{{ shopInfo.order_cancel_quantity }}</p>
          </div>
          <div>
            <div class="data-title">订单取消率</div>
            <p class="data-content">{{ shopInfo.order_cancel_ratio }}%</p>
          </div>
        </div>
      </div>
      </div>
      <!-- <div class="card" v-if="shopInfo.tags && shopInfo.tags.length > 0"> -->
      <div class="card-tab" >
        <div class="card-title">标签统计</div>
        <div class="shop-census-data" style="justify-content: start;">
          <div v-for="(item, index) in shopInfo.tags" :key="index" style="width:200px">
            <div><div class="data-title">{{ item.name }}</div>
            <p class="data-content">{{ item.quantity }}</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getShopInfo_api } from "@/api/shop.js"
import local from "@/utils/local.js"
export default {
  data() {
    return {
      active: 1,
      shopInfo: {},
      shopName: ""
    }
  },
  methods: {
    //获取数据
    async getShopInfo(id, type) {
      let res = await getShopInfo_api({
        type,
        shop_id: id
      })
      this.shopInfo = res.data.data
    },
    //改变时间
    changeActive(num) {
      this.active = num
      let id = local.get("shopId")
      this.getShopInfo(id, this.active)
    }
  },
  created() {
    let id = local.get("shopId")
    this.shopName = local.get("shopName")
    this.getShopInfo(id, this.active)
  }
}
</script>

<style lang="less" scoped>
.census {
  width: 100%;
  height: 100%;
  background: #eee;
  box-sizing: border-box;
  padding: 30px;
  
}
.screen {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  margin: 30px 0;
}
.screen-btns {
  margin-top: 20px;
}
.card-tab{
  height: 420px;
  background: #ffffff;
  border-radius: 5px;
  min-width: 400px;
  display: inline-block;
  margin-right: 10px;
  flex: 1;
}
.card {
  background: #ffffff;
  border-radius: 5px;
  min-width: 400px;
  display: inline-block;
  margin-right: 10px;
}
  .card-title {
    height: 50px;
    background: #cd0550;
    border-radius: 5px 5px 0px 0px;
    line-height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    padding: 0 20px;
  }
  .shop-census-data-one {
    min-height: 370px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
    p{
      margin-left: 10px;
    }
  }
  .shop-census-data {
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    flex-wrap: wrap;
  }
  .data-title {
    font-size: 14px;
    font-weight: bold;
    color: #666666;
    margin-bottom: 10px;
  }
  .data-content {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
.shop-census-info {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.two-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 420px;
}
</style>